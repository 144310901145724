import { Task } from './task';
import { BaseData } from './_global';
import { DRIVE_FOLDER_URL } from '@dis/gapi';
import { DocumentData, QueryDocumentSnapshot, SnapshotOptions } from '@angular/fire/firestore';
import { JobBilling } from './job-billing';
import { JobDates } from './dates';
import { ShipmentAddresses } from './addresses';

export interface Job extends BaseData {
  jobNumber: string;
  projectNumber: string;
  description: string;
  holeSection: string;
  status: string;
  shippingContainer: string;
  dates: JobDates;
  containerDateOut: string;
  containerDateIn: string;
  dateOut: string;
  dateIn: string;
  notes: string;
  folderId: string;
  jobInvoiceFolderId: string;
  jobId: string;
  tasks: Task[];
  list: string;
  customer: string;
  well: string;
  rig: string;
  location: string;
  network: string;
  ocsgLease: string;
  dateOutArray: any[];
  dateInArray: any[];
  notesArray: any[];

  billing: JobBilling;
  addresses: ShipmentAddresses;

  archiveBoolean: boolean;
  tpiBoolean: boolean;
  rentalBoolean: boolean;
  purchaseBoolean: boolean;
  callOutBoolean: boolean;
}

export class Job extends BaseData {
  constructor(data: Partial<Job>) {
    super(data);
    this.uid = data.jobNumber || null;
    this.jobNumber = data.jobNumber || null;
    this.folderId = data.folderId || data.jobFolderId || null;
    this.description = data.description || null;
    this.holeSection = data.holeSection || null;
    this.projectNumber = data.projectNumber || null;
    this.customer = data.customer || this.projectNumber?.split('-')[0].toLowerCase().trim() || null;
    this.network = data.network || null;
    this.ocsgLease = data.ocsgLease || null;
    this.location = data.location || null;
    this.well = data.well || null;
    this.rig = data.rig || null;
    this.status = data.status || null;
    this.tasks = data.tasks || [];
    this.list = data.list || null;
    this.shippingContainer = data.shippingContainer || null;
    this.dates = data.dates || null;

    // Will get rid of on a later date, still updating with the dates property
    this.containerDateOut = data.containerDateOut || null;
    this.containerDateIn = data.containerDateIn || null;
    this.dateIn = data.dateIn || null;
    this.dateOut = data.dateOut || null;
    this.dateOutArray = this.getDateArray(this.dateOut);
    this.dateInArray = this.getDateArray(this.dateIn);

    this.notes = data.notes || null;
    this.jobInvoiceFolderId = data.jobInvoiceFolderId || null;

    this.notesArray = this.getNotesArray(this.notes);
    this.billing = data.billing || null;
    this.addresses = data.addresses || null;

    this.archiveBoolean = typeof data.archiveBoolean === 'string' ?
      data.archiveBoolean === "TRUE" ? true : false
      : typeof data.archiveBoolean === 'boolean' ? data.archiveBoolean : null || null;

    this.tpiBoolean = typeof data.tpiBoolean === 'string' ?
      data.tpiBoolean === "TRUE" ? true : false
      : typeof data.tpiBoolean === 'boolean' ? data.tpiBoolean : null || null;

    this.rentalBoolean = typeof data.rentalBoolean === 'string' ?
      data.rentalBoolean === "TRUE" ? true : false
      : typeof data.rentalBoolean === 'boolean' ? data.rentalBoolean : null || null;

    this.purchaseBoolean = typeof data.purchaseBoolean === 'string' ?
      data.purchaseBoolean === "TRUE" ? true : false
      : typeof data.purchaseBoolean === 'boolean' ? data.purchaseBoolean : null || null;

    this.callOutBoolean = typeof data.callOutBoolean === 'string' ?
      data.callOutBoolean === "TRUE" ? true : false
      : typeof data.callOutBoolean === 'boolean' ? data.callOutBoolean : null || null;

    this.fromSpreadsheet(data);
  }

  public get title() {
    return this.jobNumber;
  }

  public get customerIconUrl() {
    // return `cdn/images/customers/${this.customer}.png`;
    return `assets/images/customers/${this.customer}/icon.png`
  }

  public get customerLogoUrl() {
    return `assets/images/customers/${this.customer}/logo.png`
  }

  /**
   * @deprecated
   */
  public get jobFolderId() {
    return this.folderId;
  }

  public get folderUrl() {
    return `${DRIVE_FOLDER_URL}${this.folderId}`;
  }

  public get splitProjectNumber() {
    return this.projectNumber?.split('-').slice(1).join('-');
  }

  private fromSpreadsheet(job: Partial<Job>) {
    // // @ts-expect-error
    // this.shipments = JSON.parse(job.shipments);

    if (typeof job.billing === 'string') {
      this.billing = JSON.parse(job.billing);
    }

    if (typeof job.dates === 'string') {
      this.dates = JSON.parse(job.dates);
    }

    if (typeof job.addresses === 'string') {
      this.addresses = JSON.parse(job.addresses);
    }

  }

  static toSpreadsheet(job: Job) {
    //@ts-expect-error
    job.addresses = JSON.stringify(job.addresses);
    //@ts-expect-error
    job.dates = JSON.stringify(job.dates);
    //@ts-expect-error
    job.billing = JSON.stringify(job.billing);
    //@ts-expect-error
    job.archiveBoolean = job.archiveBoolean ? 'TRUE' : 'FALSE';
    //@ts-expect-error
    job.tpiBoolean = job.tpiBoolean ? 'TRUE' : 'FALSE';
    //@ts-expect-error
    job.rentalBoolean = job.rentalBoolean ? 'TRUE' : 'FALSE';
    //@ts-expect-error
    job.purchaseBoolean = job.purchaseBoolean ? 'TRUE' : 'FALSE';
    // console.log(job);
    return job;
  }

  private getDateArray(dateString: string) {
    if (dateString) {
      const dateArray = dateString.split(',');
      return dateArray;
    } else {
      const dateArray = [];
      return dateArray;
    }
  }
  private getNotesArray(noteString: string) {
    if (noteString) {
      const noteArray = noteString.split('_');
      return noteArray.reverse();
    } else {
      const noteArray = [];
      return noteArray;
    }
  }
}

export const jobConverter = {
  toFirestore(job: Job): DocumentData {
    return { ...job };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Job {
    const data = snapshot.data(options);
    return new Job(data);
  }
};
