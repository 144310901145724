import { DRIVE_FOLDER_URL } from '@dis/gapi';
import { BaseData } from './_global';
import { Dimensionals } from './dimensionals';
import { BillingCode } from './job-billing';
import { Invoice } from './invoice';
import { ComponentShipment } from './shipment';

export interface Asset {
  owners: string[];
  quoteNumber: string;
  projectNumber: string;
  areaOfOperation: string;
  engineer: string;
  well: string;
  rig: string;
  location: string;
  ocsgLease: string;
  billingCodes: BillingCode[];
  atDisBoolean: boolean;
  writeOffBoolean: boolean;
  buyBackBoolean: boolean;
  invoices: number[];
  writeOffDate: Date;
  buyBackDate: Date;
}

export interface ComponentShopProperties {
  shopToolBoolean: boolean;
  testConnectorBoolean: boolean;
  testConnectorRelatedSeals: [{
    partNumber: string;
    description: string;
  }];
}

export interface Component extends BaseData {
  serialNumber: string;
  assemblyNumber: string;
  partNumber;
  toolType;
  description;
  asset: Asset;
  dimensionals: Dimensionals;
  shipments: ComponentShipment[];
  shopProperties: ComponentShopProperties;
  topConnection;
  bottomConnection;
  drawingNumber;
  drawingRevision;
  drawingType;
  disPoNumber;
  traceNumber;
  traceUrl: string;
  inventoryStatus;
  /**
   * @deprecated use folderId instead;
   */
  componentFolderId;
  folderId;
  lastNde;
  lastNdeDate: Date;
  lotQty;
  lotNumberBoolean;
  status: string;
  latestAssignedBuild: string;
  rack: string;
  shelf: string;
  slot: string;
  bin: string;
  container: string;
  statuses: componentStatuses;
}

export class Component extends BaseData {
  constructor(data: Partial<Component>) {
    super(data);
    this.uid = data.serialNumber || null;
    this.serialNumber = data.serialNumber || null;
    this.assemblyNumber = data.assemblyNumber || null;
    this.partNumber = data.partNumber || null;
    this.toolType = data.toolType || null;
    this.description = data.description || null;
    this.asset = data.asset || null;
    this.dimensionals = data.dimensionals || null;
    this.shipments = data.shipments || null;
    this.shopProperties = data.shopProperties || null;
    this.topConnection = data.topConnection || null;
    this.bottomConnection = data.bottomConnection || null;
    this.drawingNumber = data.drawingNumber || null;
    this.drawingRevision = data.drawingRevision || null;
    this.drawingType = data.drawingType || null;
    this.disPoNumber = data.disPoNumber || null;
    this.traceNumber = data.traceNumber || null;
    this.traceUrl = data.traceUrl || null;
    this.inventoryStatus = data.inventoryStatus || null;
    this.componentFolderId = data.componentFolderId || null;
    this.folderId = data.folderId || data.componentFolderId || null;
    this.lastNde = data.lastNde || null;
    this.lastNdeDate = data.lastNdeDate || null;
    this.lotQty = data.lotQty || null;
    this.lotNumberBoolean = data.lotNumberBoolean || null;
    this.latestAssignedBuild = data.latestAssignedBuild || null;
    this.rack = data.rack || null;
    this.shelf = data.shelf || null;
    this.slot = data.slot || null;
    this.bin = data.bin || null;
    this.container = data.container || null;
    this.statuses = data.statuses || null;

    this.fromSpreadsheet(data);
  }

  /**
   * Create model for handling NDE properties
   */
  public get nde() {
    return {
      lastNdeDate: this.lastNdeDate,
      sixMonthInspectionDate: this._sixMonthInspectionDate,
      inspectionLessThan2Weeks: this._inspectionLessThan2Weeks,
      isExpried: this._inspectionExpired,
    }
  }

  private get _sixMonthInspectionDate(): Date | null {
    const nextDate = new Date(this.lastNdeDate);
    if (nextDate && nextDate instanceof Date) {
      nextDate.setDate(nextDate.getDate() + 182.5);
      return nextDate;
    }
    return null;
  }

  private get _inspectionExpired() {
    const date = new Date(this.lastNdeDate);
    return date.getTime() > new Date().getTime();
  }

  private get _inspectionLessThan2Weeks() {
    return (this._sixMonthInspectionDate.getTime() - new Date().getTime()) < 1209600000;
  }

  public get folderUrl() {
    return `${DRIVE_FOLDER_URL}${this.folderId}`;
  }

  private fromSpreadsheet(component: Partial<Component>) {
    if (typeof component.dimensionals === 'string') {
      this.dimensionals = JSON.parse(component.dimensionals);
    }

    if (typeof component.asset === 'string') {
      this.asset = JSON.parse(component.asset);
    }

    if (typeof component.shipments === 'string') {
      this.shipments = JSON.parse(component.shipments);
    }

    if (typeof component.shopProperties === 'string') {
      this.shopProperties = JSON.parse(component.shopProperties);
    }

    if (typeof component.statuses === 'string') {
      this.statuses = JSON.parse(component.statuses);
    }

  }

  static toSpreadsheet(component: Component) {
    //@ts-expect-error
    component.dimensionals = JSON.stringify(component.dimensionals);
    //@ts-expect-error
    component.asset = JSON.stringify(component.asset);
    //@ts-expect-error
    component.shipments = JSON.stringify(component.shipments);
    //@ts-expect-error
    component.shopProperties = JSON.stringify(component.shopProperties);
    //@ts-expect-error
    component.statuses = JSON.stringify(component.statuses);
    // console.log(job);
    return component;
  }

  /**
   * @deprecated use folderUrl instead;
   */
  public get componentFolderUrl() {
    return this.folderUrl;
  }

  //TODO: check below
  /**
   * @deprecated do we actually need this?
   */
  // public get JSON_rowInfo() {
  //   return this;
  // }

  public get title() {
    return this.serialNumber;
  }
}

export interface componentStatuses {
  inventoryStatus: COMPONENT_INV_STATUS;
  shipmentStatus: COMPONENT_SHIPMENT_STATUS;
  componentStatus: COMPONENT_COMP_STATUS;
  ndeStatus: COMPONENT_NDE_STATUS;
  status: string;
}

export enum COMPONENT_INV_STATUS {
  NONE = "NONE",
  AVAILABLE = "AVAILABLE",
  NOT_AVAILABLE = "NOT AVAILABLE",
  ALL_USED = "ALL USED",
}

export enum COMPONENT_COMP_STATUS {
  NONE = "NONE",
  ORDERED = "ORDERED",
  RECEIVED = "RECEIVED",
  PARTIAL_RECEIVED = "PARTIAL RECEIVED",
  DAMAGED = "DAMAGED",
  SCRAP = "SCRAP",
  DOWNHOLE = "DOWNHOLE",
  LOST = "LOST",
  IN_CUST_POS = "IN CUST POS",
  REWORK = "REWORK",
  PARTIAL_REWORK = "PARTIAL REWORK",
  REISSUE = "REISSUE",
  VOID = "VOID",
  RETURNED = "RETURNED"
}

export enum COMPONENT_SHIPMENT_STATUS {
  NONE = "NONE",
  STAGED_BUILDS = "STAGED BUILDS",
  ASSIGNED_TO_JOB = "ASSIGNED TO JOB",
  SHIPPED = "SHIPPED",
  RECEIVED = "RECEIVED",
  RERELEASE = "RERELEASE"
}

export enum COMPONENT_NDE_STATUS {
  NONE = "NONE",
  NONCONFORMING = "NONCONFORMING",
  CONFORMING = "CONFORMING",
}

export const COMPONENT_ICON = {
  COMPONENT: 'component_exchange',
  DEPRECATED: 'disabled_by_default',
}
